import { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase/config";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import Phone from "@mui/icons-material/Phone";

const StuffList = () => {
  const [stuffList, setStuffList] = useState([]);

  useEffect(() => {
    const getStuffList = async () => {
      try {
        const contactsCollectionRef = collection(db, "stuff");
        const q = query(contactsCollectionRef, orderBy("sort", "asc"));

        const response = await getDocs(q);
        const data = response.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setStuffList(data);
      } catch (error) {
        console.error(error);
      }
    };

    getStuffList();

    return () => getStuffList();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "28px",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={2}
        style={{
          marginLeft: "14px",
          marginRight: "14px",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        {stuffList.map((stuff) => (
          <StuffItem stuff={stuff} key={stuff.id} />
        ))}
      </Stack>
    </Box>
  );
};

const StuffItem = ({ stuff }) => {
  if (stuff.phone) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={"bold"}>{stuff.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button startIcon={<Phone />} href={`tel:${stuff.phone}`}>
            {stuff.phone}
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography fontWeight={"bold"}>{stuff.label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{stuff.text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default StuffList;
