import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const LoadingIndicator = ({ text, height }) => {
  if (!height) height = "50vh";
  return (
    <div
      style={{
        height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "14px",
      }}
    >
      <CircularProgress />
      {text && (
        <Typography variant="body1" style={{ marginTop: "14px" }}>
          {text}...
        </Typography>
      )}
    </div>
  );
};

export default LoadingIndicator;
