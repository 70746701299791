import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  format,
  parse,
  setDefaultOptions,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { sv } from "date-fns/locale";

import { LocalizationProvider, svSE } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

import BookingList from "./BookingList";

const BookingFilter = () => {
  const navigate = useNavigate();
  setDefaultOptions({ locale: sv });
  const dateFormat = "yyyy-MM-dd";
  const today = format(new Date(), dateFormat);
  const referenceDate = new Date(1970, 0, 1, 0, 0, 0);

  const parseDate = (date) => {
    return parse(date, dateFormat, referenceDate);
  };

  const [selectedDay, setSelectedDay] = useState(parseDate(today));
  const [startDate, setStartDate] = useState(
    startOfMonth(parse(today, dateFormat, referenceDate))
  );
  const [endDate, setEndDate] = useState(
    endOfMonth(parse(today, dateFormat, referenceDate))
  );
  const [highlightedDays, setHighlightedDays] = useState();

  const onMonthChange = (month) => {
    setStartDate(startOfMonth(month));
    setEndDate(endOfMonth(month));
  };

  const renderDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isHighlightedDay =
      !outsideCurrentMonth && highlightedDays.indexOf(day.getDate()) > 0;

    return isHighlightedDay ? (
      <div
        style={{
          backgroundColor: "#E0E0E1",
          position: "relative",
        }}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
        <span
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            margin: "0",
            padding: "0",
          }}
        >
          <span
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
              textAlign: "center",
            }}
          >
            ...
          </span>
        </span>
      </div>
    ) : (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    );
  };

  return (
    <div style={{ marginLeft: "14px", marginRight: "14px" }}>
      {svSE && selectedDay && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={
            svSE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DateCalendar
            displayWeekNumber
            showDaysOutsideCurrentMonth
            value={selectedDay}
            onChange={(date) => setSelectedDay(date)}
            onMonthChange={(month) => onMonthChange(month)}
            slots={{
              day: renderDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
          />
          <BookingList
            startDate={startDate}
            endDate={endDate}
            setHighlightedDays={setHighlightedDays}
          />
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 96, right: 16 }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(`/bookings/add/${selectedDay}`)}
          ></SpeedDial>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default BookingFilter;
