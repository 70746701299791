import { useState, useEffect, useRef } from "react";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../firebase/config";

import compressImage from "../utils/compressImage";

import LoadingIndicator from "./LoadingIndicator";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CameraAlt from "@mui/icons-material/CameraAlt";

const ProfileImage = () => {
  const { currentUser } = auth;
  const [profileImageURL, setProfileImageURL] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const fileRef = useRef();

  useEffect(() => {
    if (currentUser?.photoURL) {
      setProfileImageURL(currentUser.photoURL);
    }
  }, [currentUser?.photoURL]);

  const handleSelectImage = () => {
    fileRef.current.click();
  };

  const handleUploadImage = async ({ file }) => {
    setIsUploading(true);
    if (!file) return;
    if (!file?.type) return;

    const fileType = file?.type.substr(file?.type.indexOf("/") + 1, 4);

    let imagePath =
      "profile/" +
      currentUser.displayName.replace(" ", "").toLocaleLowerCase() +
      "." +
      fileType;
    const profileImageRef = ref(storage, imagePath);

    const image = await compressImage({
      image: file,
      resizingWidth: "200",
    });

    const upload = uploadBytesResumable(profileImageRef, image.blob);

    upload.on(
      "state_changed",
      () => {},
      (error) => {
        console.log(error);
        setIsUploading(false);
      },
      () => {
        getDownloadURL(upload.snapshot.ref).then(async (imageURL) => {
          if (imageURL) {
            await updateProfile(currentUser, { photoURL: imageURL });
            setProfileImageURL(imageURL);

            const contactRef = doc(db, "contacts", currentUser.uid);
            await setDoc(
              contactRef,
              { userPhotoURL: imageURL },
              { merge: true }
            );
          }

          setIsUploading(false);
        });
      }
    );
  };

  if (isUploading) {
    return (
      <Box>
        <LoadingIndicator text="Laddar upp bild" height="200px" />
      </Box>
    );
  }

  return (
    <Box
      sx={{ p: 2, display: "flex", flexDirection: "column" }}
      onClick={() => handleSelectImage()}
    >
      {profileImageURL ? (
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <Avatar
            sx={{
              width: "50vw",
              height: "50vw",
              maxWidth: "300px",
              maxHeight: "300px",
            }}
            src={profileImageURL}
          ></Avatar>
          <Typography variant="caption">
            Klicka för att byta profilbild
          </Typography>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <Avatar
            sx={{
              width: "50vw",
              height: "50vw",
              maxHeight: "300px",
              maxWidth: "300px",
            }}
          >
            <CameraAlt
              sx={{
                width: "30vw",
                height: "30vw",
                maxHeight: "130px",
                maxWidth: "130px",
              }}
            />
          </Avatar>
          <Typography variant="caption">
            Klicka för att lägga till profilbild
          </Typography>
        </Stack>
      )}
      <input
        type="file"
        id="profileImage"
        ref={fileRef}
        onChange={(event) => handleUploadImage({ file: event.target.files[0] })}
        hidden
      />
    </Box>
  );
};

export default ProfileImage;
