import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { collection, addDoc } from "firebase/firestore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, svSE } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import add from "date-fns/add";
import isBefore from "date-fns/isBefore";

import Header from "../components/Header";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase/config";

import useDocument from "../utils/useDocument";

const BookingsAdd = () => {
  const navigate = useNavigate();
  let { defaultDate } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    add(new Date(defaultDate), { hours: 8 }) || null
  );
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data: userData } = useDocument({
    col: "/contacts",
    id: currentUser.uid,
  });

  useEffect(() => {
    if (startDate) {
      setEndDate(add(startDate, { hours: 1 }));
    }
  }, [startDate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const userId = currentUser.uid;
    const userName = currentUser.displayName;
    const userPhotoURL = currentUser.photoURL || "";
    const company = userData.company;
    const createdAt = new Date();

    if (!startDate || !endDate) {
      setError("Fyll i fälten start- och slutdatum");
      setLoading(false);
      return;
    }

    if (isBefore(new Date(endDate), new Date(startDate))) {
      setError("Startdatum måste vara före slutdatum");
      setLoading(false);
      return;
    }

    if (!company) {
      setError("Fyll i fältet företag i din profil och försök igen");
      setLoading(false);
      return;
    }

    const booking = {
      startDate,
      endDate,
      company,
      userId,
      userName,
      userPhotoURL,
      createdAt,
      active: true,
    };

    const bookingsCollectionRef = collection(db, "bookings");

    try {
      await addDoc(bookingsCollectionRef, booking);

      setLoading(false);
      navigate("/bookings");
    } catch (error) {
      console.error(error);
      setError(
        "Något gick fel. Testa att uppdatera eller logga ut och in igen."
      );
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={
        svSE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Header title="Skapa bokning" back={true} />

      <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{ marginLeft: "14px", marginRight: "14px" }}
        onSubmit={(e) => onSubmit(e)}
      >
        {error && (
          <Alert
            severity="error"
            onClose={() => setError("")}
            style={{ marginBottom: "14px" }}
          >
            {error}
          </Alert>
        )}
        <DateTimePicker
          label="Startdatum"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          ampm={false}
          displayWeekNumber
          minutesStep={60}
          format="yyyy-MM-dd HH:mm"
          sx={{
            mb: 2,
            width: "100%",
          }}
        />
        <DateTimePicker
          label="Slutdatum"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          ampm={false}
          displayWeekNumber
          openTo={"hours"}
          minutesStep={60}
          format="yyyy-MM-dd HH:mm"
          sx={{
            mb: 2,
            width: "100%",
          }}
          disabled={startDate ? false : true}
        />
        <Button
          size="large"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          Skapa bokning
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default BookingsAdd;
