import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

import OneSignal from "./utils/OneSignal";

import Login from "./pages/Login";
//import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Bookings from "./pages/Bookings";
import BookingsAdd from "./pages/BookingsAdd";
import Contacts from "./pages/Contacts";
import Stuff from "./pages/Stuff";
import Profile from "./pages/Profile";
import NewsAdd from "./pages/NewsAdd";

const App = () => {
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <OneSignal />
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="bookings"
          element={
            <ProtectedRoute>
              <Bookings />
            </ProtectedRoute>
          }
        />
        <Route
          path="bookings/add/:defaultDate"
          element={
            <ProtectedRoute>
              <BookingsAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path="contacts"
          element={
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          }
        />
        <Route
          path="stuff"
          element={
            <ProtectedRoute>
              <Stuff />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="news/add"
          element={
            <ProtectedRoute>
              <NewsAdd />
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login />} />
        {/*<Route path="register" element={<Register />} />*/}
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
