const compressImage = async ({
  image,
  resizingFactor,
  resizingWidth,
  qualityFactor = 0.8,
}) =>
  new Promise(async (resolve, reject) => {
    if (!image) reject("image attribute is missing");
    if (!resizingFactor && !resizingWidth)
      reject("provide attribute resizingFactor or resizingWidth");

    let compressedImage = { src: "", size: "" };

    let imgToCompress = new Image();
    imgToCompress.src = URL.createObjectURL(image);

    const { width, height } = await getImageDimensions(image);
    const aspectRatio = width / height;

    let canvasWidth = 0;
    let canvasHeight = 0;

    if (resizingFactor) {
      canvasWidth = Math.floor(width * resizingFactor);
      canvasHeight = Math.floor(height * resizingFactor);
    }

    if (resizingWidth) {
      if (width > resizingWidth) {
        canvasWidth = 200;
        canvasHeight = Math.floor(200 / aspectRatio);
      } else {
        canvasWidth = width;
        canvasHeight = height;
      }
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.drawImage(imgToCompress, 0, 0, canvasWidth, canvasHeight);

    canvas.toBlob(
      (blob) => {
        if (blob) {
          compressedImage.blob = blob;
          compressedImage.src = URL.createObjectURL(blob);
          compressedImage.size = bytesToSize(blob.size);

          resolve(compressedImage);
        } else {
          reject("Unable to compress image");
        }
      },
      "image/jpeg",
      qualityFactor
    );
  });

const getImageDimensions = async (file) => {
  let img = new Image();
  img.src = URL.createObjectURL(file);

  await img.decode();

  let width = img.width;
  let height = img.height;

  return {
    width,
    height,
  };
};

const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Byte";
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export default compressImage;
