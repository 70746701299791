import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase/config";
import emailjs from "@emailjs/browser";

const {
  REACT_APP_EMAILJS_PUBLICKEY,
  REACT_APP_EMAILJS_SERVICEID,
  REACT_APP_EMAILJS_TEMPLATEID,
} = process.env;

const sendEmail = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const contactsCollectionRef = collection(db, "contacts");
      const q = query(contactsCollectionRef);

      const response = await getDocs(q);

      let emails = "";
      response.docs.map((doc) => {
        let email = doc.data().email;
        if (!email) return null;

        if (emails) {
          emails += `,${email}`;
        } else {
          emails = email;
        }

        return null;
      });

      await emailjs.init(REACT_APP_EMAILJS_PUBLICKEY);
      const sendResult = await emailjs.send(
        REACT_APP_EMAILJS_SERVICEID,
        REACT_APP_EMAILJS_TEMPLATEID,
        { email: emails }
      );

      resolve(sendResult);
    } catch (error) {
      reject(error);
    }
  });
};

export default sendEmail;
