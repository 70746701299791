import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import Header from "../components/Header";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase/config";

import useDocument from "../utils/useDocument";
import sendEmail from "../utils/sendEmail";

const NewsAdd = () => {
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data: userData } = useDocument({
    col: "/contacts",
    id: currentUser.uid,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const title = e.target["title"].value;
    const description = e.target["description"].value;

    if (!title || !description) {
      setError("Fyll i fälten rubrik och beskrivning");
      setLoading(false);
      return;
    }

    const userId = currentUser.uid;
    const userName = currentUser.displayName;
    const userPhotoURL = currentUser.photoURL || "";
    const company = userData.company;
    const createdAt = new Date();

    const news = {
      title,
      description,
      company,
      userId,
      userName,
      userPhotoURL,
      createdAt,
      active: true,
    };

    const newsCollectionRef = collection(db, "news");

    try {
      await addDoc(newsCollectionRef, news);
      await sendEmail();

      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error(error);
      setError(
        "Något gick fel. Testa att uppdatera appen eller logga ut och in igen."
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Header title="Skapa nyhet" back={true} />

      <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{ marginLeft: "14px", marginRight: "14px" }}
        onSubmit={(e) => onSubmit(e)}
      >
        {error && (
          <Alert
            severity="error"
            onClose={() => setError("")}
            style={{ marginBottom: "14px" }}
          >
            {error}
          </Alert>
        )}
        <TextField id="title" label="Rubrik" variant="outlined" fullWidth />
        <TextField
          id="description"
          label="Beskrivning"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={10}
        />
        <Button
          size="large"
          variant="contained"
          type="submit"
          disabled={loading}
          fullWidth
        >
          Skapa nyhet
        </Button>
      </Box>
    </div>
  );
};

export default NewsAdd;
