import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import StuffList from "../components/StuffList";

const Stuff = () => {
  return (
    <>
      <Header title="Nyheter" />
      <StuffList />
      <Footer />
    </>
  );
};

export default Stuff;
