import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const { REACT_APP_VERSION } = process.env;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const email = e.target["email"]?.value;
    const password = e.target["password"]?.value;

    if (!email || !password) setError("Fyll i e-post adress och lösenord.");

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      if (
        error &&
        error.message &&
        (error.message.indexOf("user-not-found") > -1 ||
          error.message.indexOf("wrong-password") > -1)
      ) {
        console.error(error.message);
        setError("Felaktig e-post och/eller lösenord.");
      } else {
        console.error(error);
        setError("Något gick riktigt fel, försök igen senare.");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          marginLeft: "14px",
          marginRight: "14px",
          marginBottom: "24px",
        }}
      >
        <h1 style={{ marginBottom: 0, paddingBottom: 0 }}>
          Välkommen till Kronan
        </h1>
        <h2 style={{ marginTop: 0, paddingTop: 0, color: "grey" }}>Logga in</h2>
      </div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{
          marginLeft: "14px",
          marginRight: "14px",
          maxWidth: "400px",
        }}
        onSubmit={(e) => handleLogin(e)}
      >
        {error && (
          <Alert
            severity="error"
            onClose={() => setError("")}
            style={{ marginBottom: "14px" }}
          >
            {error}
          </Alert>
        )}
        <TextField
          id="email"
          type="email"
          autoComplete="email"
          label="E-post"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          id="password"
          type="password"
          autoComplete="current-password"
          label="Lösenord"
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <div style={{ marginTop: "1px" }}>
          <Link
            onClick={() => navigate("/forgot-password")}
            style={{ cursor: "pointer" }}
          >
            Glömt lösenord?
          </Link>
        </div>

        <Button
          size="large"
          variant="contained"
          type="submit"
          disabled={loading}
          style={{ marginTop: "14px" }}
          fullWidth
        >
          Logga in
        </Button>

        {/*<div style={{ marginTop: "48px" }}>
          Har du inget konto?{" "}
          <Link
            onClick={() => navigate("/register")}
            style={{ cursor: "pointer" }}
          >
            Skapa ett
          </Link>
        </div>*/}
      </Box>
      <Typography variant="caption" style={{ marginTop: "40px" }}>
        Kronan {REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

export default Login;
