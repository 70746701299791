import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import BookingsFilter from "../components/Bookings/BookingFilter";

const Bookings = () => {
  return (
    <>
      <Header title="Boka" />
      <BookingsFilter />
      <Footer />
    </>
  );
};

export default Bookings;
