import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import BookingsIcon from "@mui/icons-material/CalendarMonth";
import ContactsIcon from "@mui/icons-material/Call";
import StuffIcon from "@mui/icons-material/Handyman";
import ProfileIcon from "@mui/icons-material/Person";

const Footer = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("/");

  useEffect(() => {
    if (location?.pathname) {
      setValue(location.pathname);
    }
  }, [location]);

  const onMenuChange = (newValue) => {
    setValue(newValue);
    navigation(newValue);
  };

  return (
    <>
      <div style={{ height: "80px" }}></div>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: "10px",
          paddingBottom: "20px",
          zIndex: 1000,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => onMenuChange(newValue)}
        >
          <BottomNavigationAction
            label="Nyheter"
            value="/"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label="Boka"
            value="/bookings"
            icon={<BookingsIcon />}
          />
          <BottomNavigationAction
            label="Kontakter"
            value="/contacts"
            icon={<ContactsIcon />}
          />
          <BottomNavigationAction
            label="Bra att ha"
            value="/stuff"
            icon={<StuffIcon />}
          />
          <BottomNavigationAction
            label="Profil"
            value="/profile"
            icon={<ProfileIcon />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default Footer;

/*"theme_color": "#2f855a", */
