import { useEffect, useState } from "react";
import Booking from "./Booking";

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/config";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import getDay from "../../utils/getDay";

const BookingList = ({ startDate, endDate, setHighlightedDays }) => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    let ignore = false;

    const fetchData = async () => {
      const collectionRef = collection(db, "bookings");
      const q = query(
        collectionRef,
        where("startDate", ">", startDate),
        where("startDate", "<", endDate)
      );

      if (ignore) return;

      onSnapshot(q, (querySnapshot) => {
        const data = [];
        let highlightedDays = [0];

        querySnapshot.forEach((doc) => {
          const startDate = doc.data().startDate;
          highlightedDays.push(getDay(startDate));

          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setBookings(data);
        setHighlightedDays(highlightedDays);
      });
    };

    fetchData();

    return () => {
      ignore = true;
    };
  }, [startDate, endDate, setHighlightedDays]);

  if (!startDate || !endDate) return null;

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "28px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={2}
        style={{
          width: "100%",
          maxWidth: "600px",
        }}
      >
        {bookings &&
          bookings.map((booking) => (
            <Booking booking={booking} key={booking.id} />
          ))}
      </Stack>
    </Box>
  );
};

export default BookingList;
