import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import BackIcon from "@mui/icons-material/ChevronLeftTwoTone";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import HomeIcon from "@mui/icons-material/Home";
import BookingsIcon from "@mui/icons-material/CalendarMonth";
import ContactsIcon from "@mui/icons-material/Call";
import StuffIcon from "@mui/icons-material/Handyman";
import ProfileIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/ExitToApp";

import { signOut } from "firebase/auth";

import { auth } from "../firebase/config";

const Header = ({ back, title }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {back ? (
            <BackIcon
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigation(-1)}
            >
              <MenuIcon />
            </BackIcon>
          ) : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(!isDrawerOpen)}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <img src="/crown.png" width="16" height="16" alt="Kronan Logo" />
        </Toolbar>
      </AppBar>
      <div style={{ height: "80px" }}></div>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "14px",
            width: "70vw",
          }}
        >
          <img
            src="/logo192.png"
            width="50"
            height="50"
            style={{
              marginLeft: "14px",
              marginTop: "14px",
              marginBottom: "14px",
            }}
            alt="Kronan Logo"
          />
          <MenuList>
            <MenuItem
              onClick={() => navigation("/")}
              selected={location.pathname === "/"}
            >
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Nyheter</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => navigation("/bookings")}
              selected={location.pathname === "/bookings"}
            >
              <ListItemIcon>
                <BookingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Boka</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => navigation("/contacts")}
              selected={location.pathname === "/contacts"}
            >
              <ListItemIcon>
                <ContactsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Kontakter</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => navigation("/stuff")}
              selected={location.pathname === "/stuff"}
            >
              <ListItemIcon>
                <StuffIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Bra att ha</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => navigation("/profile")}
              selected={location.pathname === "/profile"}
              divider
            >
              <ListItemIcon>
                <ProfileIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profil</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => signOut(auth)}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logga ut</ListItemText>
            </MenuItem>
          </MenuList>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
