//import { ref, getDownloadURL } from "firebase/storage";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FaceIcon from "@mui/icons-material/Face";

import BookingDelete from "./BookingDelete";

import formatDate from "../../utils/formatDate";
import getTime from "../../utils/getTime";

const Booking = ({ booking }) => {
  return (
    <Card variant="outlined" style={{ marginBottom: "14px" }}>
      <Box sx={{ p: 2, display: "flex" }}>
        <Avatar
          variant="rounded"
          src={booking.userPhotoURL}
          alt={booking.userName}
        />
        <Stack sx={{ flex: 1, px: 2 }}>
          <Typography fontWeight={700}>
            {formatDate(booking.startDate)}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {getTime(booking.startDate)}-{getTime(booking.endDate)}
          </Typography>
        </Stack>
        <BookingDelete booking={booking} />
      </Box>
      <Box
        sx={{ pl: 2, pb: 2, display: "flex" }}
        style={{ backgroundColor: "transparent" }}
      >
        <Chip
          icon={<FaceIcon />}
          label={booking.userName + " | " + booking.company}
          variant="outlined"
          size="small"
        />
      </Box>
    </Card>
  );
};

export default Booking;
