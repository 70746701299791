import { useContext } from "react";
import { doc, deleteDoc } from "firebase/firestore";

import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/config";

import Confirm from "../Confirm";

const BookingDelete = ({ booking }) => {
  const { currentUser } = useContext(AuthContext);
  const bookingDate = new Date(booking.startDate.seconds * 1000);

  const handleDeleteBooking = async () => {
    if (!booking.id) return;

    await deleteDoc(doc(db, "bookings", booking.id));
  };

  if (booking.userId === currentUser.uid && bookingDate > new Date()) {
    return (
      <Confirm
        questionText="Vill du radera bokningen?"
        confirmText="Ja"
        declineText="Nej"
        callBack={handleDeleteBooking}
      />
    );
  } else {
    return null;
  }
};

export default BookingDelete;
