const formatDate = (date) => {
  if (!date) return;

  return new Intl.DateTimeFormat("sv-SE", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date.toDate());
};

export default formatDate;
