import { useState } from "react";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import DeleteIcon from "@mui/icons-material/Delete";

const Confirm = ({ questionText, confirmText, declineText, callBack }) => {
  const [open, setOpen] = useState(false);

  const handleCallBack = () => {
    setOpen(false);

    callBack();
  };

  if (!open) {
    return (
      <DeleteIcon
        onClick={() => setOpen(true)}
        style={{ color: "grey", cursor: "pointer" }}
      />
    );
  }

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle>{questionText}</DialogTitle>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "28px",
        }}
      >
        <Button onClick={handleCallBack}>{confirmText}</Button>
        <Button onClick={() => setOpen(false)}>{declineText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
