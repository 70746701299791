const getTime = (date) => {
  if (!date) return;

  return new Intl.DateTimeFormat("sv-SE", {
    hour: "numeric",
    minute: "numeric",
  }).format(date.toDate());
};

export default getTime;
