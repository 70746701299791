import { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase/config";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import ContactItem from "./ContactItem";

const ContactList = () => {
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    const getContactList = async () => {
      try {
        const contactsCollectionRef = collection(db, "contacts");
        const q = query(contactsCollectionRef, orderBy("displayName", "asc"));

        const response = await getDocs(q);
        const data = response.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setContactList(data);
      } catch (error) {
        console.error(error);
      }
    };

    getContactList();

    return () => getContactList();
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "28px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={2}
        style={{
          width: "100%",
          maxWidth: "600px",
        }}
      >
        {contactList.map((contact) => (
          <ContactItem contact={contact} key={contact.id} />
        ))}
      </Stack>
    </Box>
  );
};

export default ContactList;
