import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ContactList from "../components/Contacts/ContactList";

const Contacts = () => {
  return (
    <>
      <Header title="Kontakter" />
      <ContactList />
      <Footer />
    </>
  );
};

export default Contacts;
