import { useState } from "react";
import { updateProfile, updateEmail } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/config";

import useDocument from "../utils/useDocument";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import Header from "../components/Header";
import Footer from "../components/Footer";
import LoadingIndicator from "../components/LoadingIndicator";
import ProfileImage from "../components/ProfileImage";

const Profile = () => {
  const { currentUser } = auth;
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, loading } = useDocument({
    col: "/contacts",
    id: currentUser.uid,
  });

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setFormError(false);
    setIsSubmitting(true);

    const displayName = e.target["displayName"]?.value;
    const company = e.target["company"]?.value;
    const website = e.target["website"]?.value;
    const email = e.target["email"]?.value;
    const mobile = e.target["mobile"]?.value;
    const iceName = e.target["iceName"]?.value;
    const iceMobile = e.target["iceMobile"]?.value;
    const misc = e.target["misc"]?.value;

    const updatedProfile = {};

    if (displayName !== data?.displayName)
      updatedProfile.displayName = displayName;
    if (company !== data?.company) updatedProfile.company = company;
    if (website !== data?.website) updatedProfile.website = website;
    if (email !== data?.email) updatedProfile.email = email;
    if (mobile !== data?.mobile) updatedProfile.mobile = mobile;
    if (iceName !== data?.iceName) updatedProfile.iceName = iceName;
    if (iceMobile !== data?.iceMobile) updatedProfile.iceMobile = iceMobile;
    if (misc !== data?.misc) updatedProfile.misc = misc;

    const contactRef = doc(db, "contacts", currentUser.uid);
    await setDoc(contactRef, updatedProfile, { merge: true });

    if (displayName !== data?.displayName) {
      await updateProfile(currentUser, { displayName: displayName });
    }

    if (email !== data?.email) {
      await updateEmail(currentUser, email);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Header title="Profil" />
      {loading && <LoadingIndicator text="Hämtar profil" />}
      {!loading && data && (
        <Box
          component="form"
          noValidate
          autoComplete="off"
          style={{
            width: "100%",
            marginBottom: "28px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          onSubmit={(e) => handleUpdateProfile(e)}
        >
          <Stack
            spacing={2}
            style={{
              width: "100%",
              maxWidth: "600px",
              padding: "14px",
            }}
          >
            {formError && (
              <Alert
                severity="error"
                onClose={() => setFormError("")}
                style={{ marginBottom: "14px" }}
              >
                {formError}
              </Alert>
            )}
            <ProfileImage />
            <TextField
              id="displayName"
              type="text"
              autoComplete="name"
              label="Namn"
              variant="outlined"
              defaultValue={data?.displayName}
              fullWidth
            />
            <TextField
              id="company"
              type="text"
              autoComplete="company"
              label="Företag"
              variant="outlined"
              margin="normal"
              defaultValue={data?.company}
              fullWidth
            />
            <TextField
              id="website"
              type="url"
              autoComplete="url"
              label="Hemsida"
              variant="outlined"
              margin="normal"
              defaultValue={data?.website}
              fullWidth
            />
            <TextField
              id="email"
              type="email"
              autoComplete="email"
              label="E-post"
              variant="outlined"
              margin="normal"
              defaultValue={data?.email}
              fullWidth
            />
            <TextField
              id="mobile"
              type="tel"
              autoComplete="tel"
              label="Mobil"
              variant="outlined"
              margin="normal"
              defaultValue={data?.mobile}
              fullWidth
            />
            <TextField
              id="iceName"
              type="text"
              autoComplete="name"
              label="ICE Namn"
              variant="outlined"
              margin="normal"
              defaultValue={data?.iceName}
              fullWidth
            />
            <TextField
              id="iceMobile"
              type="tel"
              autoComplete="tel"
              label="ICE Mobil"
              variant="outlined"
              margin="normal"
              defaultValue={data?.iceMobile}
              fullWidth
            />
            <TextField
              id="misc"
              label="Övrigt"
              variant="outlined"
              margin="normal"
              fullWidth
              defaultValue={data?.misc}
              multiline
              rows={10}
            />

            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              style={{ marginTop: "14px", marginBottom: "24px" }}
              fullWidth
            >
              Uppdatera profil
            </Button>
          </Stack>
        </Box>
      )}
      <Footer />
    </>
  );
};

export default Profile;
