import { useEffect } from "react";
import OneSignal from "react-onesignal";

const runOneSignal = async () => {
  if (!OneSignal) {
    await OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APPID,
    });
    OneSignal.Slidedown.promptPush();
  }
};

const InitOneSignal = () => {
  useEffect(() => {
    runOneSignal();
  });
};

export default InitOneSignal;
