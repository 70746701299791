import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { equalTo } from "firebase/database";
import { db } from "../firebase/config";

const useDocument = ({ col, where, id, callback }) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;

    const getDocument = async () => {
      setLoading(true);

      let q = "";
      let collectionRef = "";
      let response = "";
      let data = "";

      try {
        if (id) {
          collectionRef = doc(db, col, id);
        } else {
          collectionRef = collection(db, col);
        }

        if (where) {
          q = query(collectionRef, equalTo(where));
        } else {
          q = query(collectionRef);
        }

        if (id) {
          response = await getDoc(collectionRef);

          data = { ...response.data(), id: response.id };
        } else {
          response = await getDocs(q);

          data = response.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        }

        if (data && !ignore) {
          setData(data);
        } else {
          setData(undefined);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    };

    getDocument();

    return () => {
      ignore = true;
    };
  }, [col, where, id, callback]);

  return { data, where, loading, error };
};

export default useDocument;
