import { useContext } from "react";
import { doc, deleteDoc } from "firebase/firestore";

import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/config";

import Confirm from "../Confirm";

const NewsDelete = ({ news }) => {
  const { currentUser } = useContext(AuthContext);

  const handleDeleteNews = async () => {
    if (!news.id) return;

    await deleteDoc(doc(db, "news", news.id));
  };

  if (news.userId === currentUser.uid) {
    return (
      <Confirm
        questionText="Vill du radera nyheten?"
        confirmText="Ja"
        declineText="Nej"
        callBack={handleDeleteNews}
      />
    );
  } else {
    return null;
  }
};

export default NewsDelete;
