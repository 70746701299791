const getDay = (date) => {
  if (!date) return;

  return parseInt(
    new Intl.DateTimeFormat("sv-SE", {
      day: "numeric",
    }).format(date.toDate())
  );
};

export default getDay;
