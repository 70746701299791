import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/config";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Alert from "@mui/material/Alert";

import NewsItem from "./NewsItem";

const NewsList = () => {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getNewsList = async () => {
      try {
        setError(null);
        const newsCollectionRef = collection(db, "news");
        const q = query(
          newsCollectionRef,
          orderBy("createdAt", "desc"),
          limit(10)
        );

        onSnapshot(q, (querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push({
              ...doc.data(),
              id: doc.id,
            });
          });

          setNewsList(data);
        });
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    };

    getNewsList();

    return () => {
      getNewsList();
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "28px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error && (
        <Alert
          severity="error"
          onClose={() => setError("")}
          style={{ marginBottom: "14px" }}
        >
          {error}
        </Alert>
      )}
      <Stack
        spacing={2}
        style={{
          width: "100%",
          maxWidth: "600px",
        }}
      >
        {newsList.map((news) => (
          <NewsItem news={news} key={news.id} />
        ))}
      </Stack>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", bottom: 96, right: 16 }}
        icon={<SpeedDialIcon />}
        onClick={() => navigate("/news/add")}
      ></SpeedDial>
    </Box>
  );
};

export default NewsList;
