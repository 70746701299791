import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/config";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

const { REACT_APP_VERSION } = process.env;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const email = e.target["email"]?.value;

    if (!email || email.indexOf("@") < 0 || email.indexOf(".") < 0) {
      setError("Fyll i e-post adress");
      setLoading(false);
      return null;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setLoading(false);
      setSuccess(email);
      setEmail("");
    } catch (error) {
      setLoading(false);

      if (error) {
        console.error(error);
        setError("Något gick riktigt fel, försök igen senare.");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          marginLeft: "14px",
          marginRight: "14px",
          marginBottom: "24px",
        }}
      >
        <h1 style={{ marginBottom: 0, paddingBottom: 0 }}>
          Välkommen till Kronan
        </h1>
        <h2 style={{ marginTop: 0, paddingTop: 0, color: "grey" }}>
          Återställ lösenord
        </h2>
      </div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        style={{
          marginLeft: "14px",
          marginRight: "14px",
          maxWidth: "400px",
        }}
        onSubmit={(e) => handleLogin(e)}
      >
        {error && (
          <Alert
            severity="error"
            onClose={() => setError("")}
            style={{ marginBottom: "14px" }}
          >
            {error}
          </Alert>
        )}

        {success && (
          <>
            <Alert
              severity="success"
              onClose={() => navigate("/login")}
              style={{ marginBottom: "14px" }}
            >
              Återställning påbörjad, vänligen kontrollera din e-post {success}.
            </Alert>
            <Button
              size="large"
              variant="contained"
              type="button"
              disabled={loading}
              style={{ marginTop: "14px", marginRight: "14px" }}
              onClick={() => navigate("/login")}
              fullWidth
            >
              OK
            </Button>
          </>
        )}

        {!success && (
          <>
            <TextField
              id="email"
              type="email"
              autoComplete="email"
              label="E-post"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />

            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={loading}
              style={{ marginTop: "14px" }}
              fullWidth
            >
              Återställ lösenord
            </Button>
            <Button
              size="large"
              variant="text"
              type="button"
              disabled={loading}
              style={{ marginTop: "14px", marginRight: "14px" }}
              onClick={() => navigate("/login")}
              fullWidth
            >
              Avbryt
            </Button>
          </>
        )}
      </Box>
      <Typography variant="caption" style={{ marginTop: "40px" }}>
        Kronan {REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

export default ForgotPassword;
