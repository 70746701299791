import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import AttributionIcon from "@mui/icons-material/Attribution";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CameraAlt from "@mui/icons-material/CameraAlt";
import Phone from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import Email from "@mui/icons-material/Email";

const ContactItem = ({ contact }) => {
  const color = { secondary: "grey" };

  return (
    <Card
      style={{
        marginLeft: "14px",
        marginRight: "14px",
      }}
      variant="outlined"
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {contact.userPhotoURL ? (
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Avatar
              sx={{
                width: "30vw",
                height: "30vw",
                maxHeight: "300px",
                maxWidth: "300px",
              }}
              src={contact.userPhotoURL}
            ></Avatar>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Avatar
              sx={{
                width: "30vw",
                height: "30vw",
                maxHeight: "300px",
                maxWidth: "300px",
              }}
            >
              <CameraAlt
                sx={{
                  width: "20vw",
                  height: "20vw",
                  maxHeight: "130px",
                  maxWidth: "130px",
                }}
              />
            </Avatar>
          </Stack>
        )}
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
          <Typography variant="h5">{contact.displayName}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
          <Typography variant="subtitle1">{contact.company}</Typography>
        </Stack>
        {contact.website && (
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Typography variant="subtitle2">
              <a href={contact.website} target="_blank" rel="noreferrer">
                {contact.website}
              </a>
            </Typography>
          </Stack>
        )}
        {contact.email && (
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Typography variant="caption">{contact.email}</Typography>
          </Stack>
        )}
        {contact.mobile && (
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Typography variant="caption">{contact.mobile}</Typography>
          </Stack>
        )}
        <Stack
          direction="row"
          spacing={3}
          sx={{ justifyContent: "center", marginTop: "14px" }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {contact.mobile && (
              <Button startIcon={<Phone />} href={`tel:${contact.mobile}`}>
                Ring
              </Button>
            )}
            {contact.mobile && (
              <Button
                startIcon={<TextsmsIcon />}
                href={`sms:${contact.mobile}`}
              >
                SMS
              </Button>
            )}
            {contact.email && (
              <Button startIcon={<Email />} href={`mailto:${contact.email}`}>
                E-post
              </Button>
            )}
          </ButtonGroup>
        </Stack>

        <Stack
          direction="column"
          spacing={0}
          sx={{ justifyContent: "center", marginTop: "14px" }}
        >
          {contact.iceName && contact.iceMobile && (
            <Accordion sx={{ boxShadow: "none", border: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: color.secondary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FavoriteIcon sx={{ color: color.secondary }} />
                <Typography sx={{ color: color.secondary }}>
                  &nbsp;Akut kontaktperson
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {contact.mobile && (
                  <Button
                    startIcon={<Phone />}
                    color="primary"
                    variant="contained"
                    fullWidth
                    href={`tel:${contact.iceMobile}`}
                  >
                    {`Ring ${contact.iceName}`}
                    <br />
                    {`(${contact.iceMobile})`}
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {contact.misc && (
            <Accordion sx={{ boxShadow: "none", border: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: color.secondary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AttributionIcon sx={{ color: color.secondary }} />
                <Typography sx={{ color: color.secondary }}>
                  &nbsp;Övrigt
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{contact.misc}</Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </Box>
    </Card>
  );
};

export default ContactItem;
