import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FaceIcon from "@mui/icons-material/Face";

import formatDate from "../../utils/formatDate";
import NewsDelete from "./NewsDelete";

const NewsItem = ({ news }) => {
  return (
    <Card
      style={{ marginLeft: "14px", marginRight: "14px" }}
      variant="outlined"
    >
      <Box sx={{ p: 2, display: "flex" }}>
        <Avatar variant="rounded" src={news.userPhotoURL} alt={news.userName} />
        <Stack sx={{ px: 2, flex: 1 }}>
          <Typography fontWeight={700}>{news.title}</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {formatDate(news.createdAt)}
          </Typography>
        </Stack>
        <NewsDelete news={news} />
      </Box>
      <Box
        sx={{ pl: 2, pr: 2, pb: 2, display: "flex" }}
        style={{ backgroundColor: "transparent" }}
      >
        <Typography variant="body2" color="text.secondary">
          {news.description}
        </Typography>
      </Box>
      <Box
        sx={{ pl: 2, pb: 2, display: "flex" }}
        style={{ backgroundColor: "transparent" }}
      >
        <Chip
          icon={<FaceIcon />}
          label={news.userName + " | " + news.company}
          variant="outlined"
          size="small"
        />
      </Box>
    </Card>
  );
};

export default NewsItem;
