import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import NewsList from "../components/News/NewsList";

const Home = () => {
  return (
    <>
      <Header title="Nyheter" />
      <NewsList />
      <Footer />
    </>
  );
};

export default Home;
